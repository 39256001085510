import React from 'react';
import { useLocation } from 'react-router-dom';

const Register = () => {
  const location = useLocation();
  const redirectToSignup = (isInvitation) => {
    let redirectUri = `${window.location.protocol}//${window.location.hostname}/callback`
    if(isInvitation) {
      // If the user is invited, redirect to the autocase.com app
      redirectUri = `${process.env.REACT_APP_BUILDINGS_HOST}?collab-invite`
    }
    window.location.href = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/login?state=g6Fo2SB2VEhBcUl4MEpZVUJueF9TeTZ1LWxidmJIdlBWZzdvOKN0aWTZIDd2SzJ5aTA3dzdzX0p0dzNCZ0FjSFVsR2syQ0duLS1Zo2NpZNkga3ZVZ2I5V0FwWDNiT0NkY0tEdkpXT0pGbE1ndkpuS0Y&client=1WAZwqWfaLnF3PH0Uh92mzbWhrbxNA62&protocol=oauth2&response_type=token%20id_token&redirect_uri=${redirectUri}&scope=openid%20email&nonce=BLi8p2Pmk3VzRb7wsbfp865hXI6rLIY6&auth0Client=eyJuYW1lIjoiYW5ndWxhci1hdXRoMCIsInZlcnNpb24iOiIzLjAuNCIsImVudiI6eyJhdXRoMC1qcyI6IjkuMTEuMyIsImF1dGgwLmpzIjoiOS4xMS4zIn19&mode=signup`
  }
  const searchParams = new URLSearchParams(location.search);
  const isInvitation = searchParams.has('collab-invite')

  redirectToSignup(isInvitation)

  return <div />;
};
export default Register;


